<template>
    <div class="board-container">
        <v-simple-table class="table-board table-board--input">
            <tbody>
                <tr>
                    <th style="width:200px;">제목</th>
                    <td>
                        <input type="text" id="subject" v-model="board.subject" @input="$emit('input', board)" class="input w-100" />
                    </td>
                </tr>
                <tr>
                    <th style="width:200px;">작성자 이름</th>
                    <td>
                        <input type="text" id="writer-name" v-model="board.writer.name" @input="$emit('input', board)" class="input w-100" />
                    </td>
                </tr>
                <tr v-show="false">
                    <th style="width:200px;">첨부파일</th>
                    <td>
                        <div class="board-view-file">
                            <span class="head">
                                <svg
                                    version="1.1"
                                    id="레이어_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 409 513.6"
                                    style="enable-background:new 0 0 409 513.6;"
                                    xml:space="preserve"
                                >
                                    <path
                                        d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82
                        c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8
                        M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7
                        c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
                                    />
                                </svg>
                                첨부파일 :
                            </span>
                            <ul v-show="false" class="lists">
                                <li><a href="">파일제목_01.jpg,</a></li>
                                <li><a href="">파일제목_02.jpg,</a></li>
                                <li><a href="">파일제목_03.jpg,</a></li>
                                <li><a href="">파일제목_04.jpg,</a></li>
                                <li><a href="">파일제목_05.jpg</a></li>
                            </ul>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th style="width:200px;">내용</th>
                    <td>
                        <div class="board-content">
                            <naver-smarteditor v-model="board.content" @input="$emit('input', board)"></naver-smarteditor>
                        </div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <div v-show="false" role="table" class="board-table board-table--preview">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        이전글
                    </div>
                    <div role="cell">
                        <div class="title"><a href="">이전 게시글 제목입니다.</a></div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">
                        이전글
                    </div>
                    <div role="cell">
                        <div class="title"><a href="">다음 게시글 제목입니다.</a></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="board-buttons">
            <div class="left">
                <a class="button button--border" @click="$router.go(-1)"><span>리스트</span></a>
            </div>
            <div class="right">
                <a class="button button--border" @click="$router.go(-1)"><span>취소</span></a>
                <a class="button button--primary" @click="$emit('save')"><span>확인</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            board: this.$props.value,
        };
    },
    watch: {
        value() {
            this.board = this.value;
        },
    },
};
</script>
